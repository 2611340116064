.container {
  position: fixed;
  top: 20px;
  left: 20px;
  bottom: 20px;
  right: 20px;
  background: white;
  z-index: 99999;
  color: black;
  padding: 20px;
  overflow: auto;
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #2a2a2a;
  z-index: 99999;
}
.booking {
  display: flex;
  margin: 10px 0;
}

.time {
  margin-right: 30px;
  font-weight: bold;
}

.covers {
  margin-right: 20px;
  min-width: 20px;
  display: inline-block;
  font-weight: bold;
}

.details {
  display: inline-block;
}

.container p,
.container span {
  vertical-align: top;
}

.hiddenNotes {
  opacity: 0.6;
}

.notes {
  font-size: 0.9em;
  user-select: none;
  cursor: pointer;
}

.tag {
  font-weight: bold;
  margin-right: 5px;
}

.deselected {
  opacity: 0.7;
}

.container button {
  padding: 5px;
  margin: 5px;
}

@media print {
  .container {
    position: initial;
  }
  .blackout {
    position: initial;
  }
}
