.restaurant-selector-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
}

.restaurant-selector-wrapper .window {
  background: #252525;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}

.restaurant-selector-wrapper p {
  padding: 0 40px;
}

.restaurant-selector-wrapper .restaurant-selector {
  background: #121212;
  padding: 20px;
}

.restaurant-selector-wrapper .restaurant-name {
  color: #286e8f;
  border-bottom: 2px solid #252525;
  padding: 10px 10px 12px 10px;
}

.restaurant-selector-wrapper .restaurant-name:last-child {
  border-bottom: none;
}
