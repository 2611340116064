input,
.eui-time-picker {
  background-color: #252525;
  border: none;
  padding: 10px 0;
  margin: 10px 10%;
  width: 80%;
  outline-width: 0;
  outline: none;
  text-align: center;
  color: #286e8f;
  font-size: 0.7em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.double-row input {
  display: inline-block;
  width: 47%;
  margin: 0;
}
.double-row input:first-child {
  margin-right: 6%;
}
.submit {
  top: 20px;
  width: 100px;
  border-radius: 100px;
  background-color: #252525;
  padding: 10px;
  position: absolute;
  left: 70px;
  font-size: 0.8em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.submit svg {
  vertical-align: middle;
}
.save {
  left: unset;
  right: 70px;
}
.time-picker {
  background-color: #252525;
  padding: 5px;
  min-height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0 0 5px black;
}
.hours {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.hours > div {
  background: #2c2c2c;
  padding: 10px 15px;
  margin: 5px;
  width: 8%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}
.hours.am-pm {
  justify-content: center;
}
.hours.minutes > * {
  width: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-service:hover {
  width: 26px;
  height: 26px;
  opacity: 1;
  color: #47aecc;
  background: #2c2c2c;
  border: 2px solid #2c2c2c;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}
.submit-time-picker {
  background: rgba(71, 174, 204, 0.9);
  color: #252525;
  font-weight: bold;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.options {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
}

.hours div.selected {
  background: rgba(40, 110, 143, 1);
  color: #252525;
}
.state-hours {
  display: flex !important;
  justify-content: center;
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.8em;
  padding: 20px;
}

.numbers {
  width: 250px;
  align-self: center !important;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
}
.numbers div {
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-content: center;
  display: flex;
  line-height: 40px;
  font-size: 1.5em;

  background: #2c2c2c;
  margin: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.keypad {
  width: 300px;
  height: 350px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
