.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

input:-webkit-autofill {
  transition: all 0s 50000s;
}

@media print {
  *:not(.printable) {
    display: none;
  }
  body,
  html,
  #root {
    height: unset;
  }
}
