.booking-modal .tag {
  background: #3a3a3a;
  margin: 5px 10px;
  padding: 6px;
  border-radius: 100px;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.booking-modal .tag.selected {
  border: 2px solid #47aecc;
}

.booking-modal .tags {
  overflow-y: auto;
  flex: 1;
  min-width: 200px;
  height: 150px;
  border: 2px solid #3a3a3a;
  box-sizing: border-box;
}

.booking-modal .mandatory::placeholder {
  color: #ffa500cc;
}

.booking-modal .mandatory {
  color: #47aecc;
}

.booking-modal {
  background: black;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking-modal b {
  color: #47aecc;
}

.booking-modal .booking-details-holder {
  background: #252525;
  display: flex;
  height: calc(100% - 100px);
  width: calc(100% - 100px);
  box-sizing: border-box;
  padding: 50px;
  flex-direction: column;
  overflow-y: auto;
  transition: 0.3s ease;
  transform: scale(1);
  transform-origin: bottom;
}

.booking-modal.invisible .booking-details-holder {
  transform: scale(0.6);
}

.booking-modal {
  transition: 0.3s ease;
  opacity: 1;
}

.booking-modal.invisible {
  opacity: 0;
  pointer-events: none;
}

.booking-details {
  display: flex;
  flex-direction: column;
  background: #121212;
  padding: 20px 15px 0 15px;
  justify-content: space-between;
}

.booking-details input {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  font-size: 14px;
  padding-left: 10px;
  text-align: left;
  color: #47aecc;
  background: #3a3a3a;
}

.booking-details textarea {
  flex: 1;
  background: #3a3a3a;
  min-width: 200px;
  outline: none;
  padding: 10px;
  color: #47aecc;
  font-size: 14px;
  border: none;
  height: 150px;
  box-sizing: border-box;
}

.booking-modal .horizontal {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}

.booking-modal .initials-options {
  border: 2px solid #3a3a3a;
  flex: 1;
  flex-direction: row;
  display: flex;
  overflow-x: auto;
}

.booking-modal .initials-options > div {
  background: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 20px;
}

.booking-modal .booking-details > * {
  margin-bottom: 20px;
}

.booking-modal .bottom-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: #286e8f;
}

.booking-modal .bottom-buttons .deactivated {
  opacity: 0.7;
  pointer-events: none;
  color: #626262;
}

.booking-modal .bottom-buttons > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-radius: 100px;
  margin: 20px 0;
  min-width: 120px;
  background: #121212;
}

@media screen and (max-width: 500px) {
  .booking-modal .booking-details-holder {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 380px) {
  .booking-modal .booking-details-holder {
    padding: 20px;
  }
}
