.calendar-overlay table {
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.date-picker {
  width: 500px;
  height: 350px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}
.date-picker th {
  color: rgba(255, 255, 255, 0.66);
  font-size: 0.8em;
}
span.schedule-first.schedule-last {
  background-color: rgba(37, 37, 36, 1);
}
.calendar-overlay td {
  border: 1px solid #252524;
  width: 14.29%;
  padding: 0;
  vertical-align: top;
  position: relative;
  background-color: #101010;
  color: rgba(255, 255, 255, 0.66);
}
.calendar-overlay th {
  vertical-align: middle;
}
.calendar-overlay th h2 {
  display: inline-block;
}
.calendar-overlay th h2 svg {
  display: inline-block;
  height: 100%;
  line-height: 100%;
  vertical-align: middle;
  color: #46aecc;
}
.calendar-overlay h3 {
  margin: 0;
  padding: 5px;
}

.calendar-overlay thead {
  background-color: #252524;
}
.calendar-overlay .today {
  background-color: rgb(71 174 204 / 20%);
  border-color: #47aecc;
}
.calendar-overlay .different-month {
  background-color: #2a2a2a;
}
.calendar-overlay td p {
  font-family: monospace;
  margin: 0;
  top: 10px;
  right: 20px;
  font-size: 12px;
  position: absolute;
}
.calendar-overlay td span {
  font-size: 10px;
  position: absolute;
  bottom: 3px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: rgba(37, 37, 36, 0.5);
}

.calendar-overlay .schedule-first {
  left: 3px;
}

.calendar-overlay .schedule-last {
  right: 3px;
}
