.navbar-today {
  height: 42px;
  width: 42px;
  color: rgba(255, 0, 0, 1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  background: rgba(255, 0, 0, 0.4);
  border: 0;
  border-radius: 100px;
  position: absolute;
  margin-left: 250px;
}

.navbar-today svg {
  vertical-align: middle;
}
.navbar .add-booking {
  width: 46px;
  height: 46px;
  background: #2e2e2e;
  display: inline-flex;
  margin-right: 10px;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
}
.navbar .add-booking svg {
  height: 30px;
}
.navbar .add-booking.selected {
  background: #47aecc;
}
.navbar .add-booking.selected svg.icon {
  color: #2e2e2e;
}
.nav-warning {
  background: #ff450082;
  position: absolute;
  top: 9px;
  min-height: 40px;
  left: 80px;
  right: calc(50% + 100px);
  border-radius: 5px;
  color: #dbdbdb;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 6px;
}
.nav-warning svg {
  margin-right: 6px;
}

.nav-warning .short {
  display: none;
}

@media screen and (max-width: 730px) {
  .nav-warning .short {
    display: unset;
  }
  .nav-warning .long {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .nav-warning .short {
    display: none;
  }
  .nav-warning .long {
    display: none;
  }
  .nav-warning svg {
    margin-right: 0;
  }
}
