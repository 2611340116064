body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a2a2a;
  color: rgba(255, 255, 255, 0.66);
}
html,
body {
  overscroll-behavior: none;
  height: 100%;
  width: 100%;
  overflow-x: overlay;
}
div#root {
  height: 100%;
}
table {
  border-spacing: 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  outline: none;
}
.refresh button {
  font-family: inherit;
  border: 0;
  background: #2e2e2e;
  color: #47aecc;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 100px;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}
.refresh {
  position: fixed;
  top: 0;
  left: 10px;
  z-index: 9999;
  background: rgb(144 100 19);
  padding: 10px;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  border-radius: 0 0 30px 30px;
}
.refresh {
  display: none;
}
.tablesection,
.table {
  min-width: 70px;
  text-align: center;
  vertical-align: top;
  height: 100px;
  padding: 0;
}
.tablescontainer .hidden {
  display: none;
}
.table p {
  margin: 5px 0 0 0;
  font-size: 0.8em;
  clear: both;
}
.table > p {
  color: rgba(255, 255, 255, 0.87);
  font-size: 1em;
}
.tablesection {
  min-width: 40px;
  background-color: #252525;
  line-height: 100px;
  vertical-align: middle;
}
.table {
  background-color: #121212;
  position: relative;
}
.tablescontainer {
  position: fixed;
  bottom: 0;
  background-color: #121212;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  height: 100px;
  transition: height 0.5s ease;
  transition-delay: 0s;
}

.tables,
.tables tr,
.tables tr td {
  padding: 0;
  height: 100px;
}
.calendarbutton {
  width: 180px;
}
.dayofweek {
  color: rgba(255, 255, 255, 0.87);
  font-size: 1.5em;
  font-weight: bold;
}
.fullWidth {
  list-style-type: none;
  margin: 0;
  vertical-align: middle;
}
ul.bookingUL {
  padding: 0;
}

table.pacing {
  padding: 10px;
  width: 100%;
  background-color: #252525;
  margin: 0;
  height: 30px;
  vertical-align: middle;
}
.pacingcovers {
  margin-left: 0;
  width: 20px;
  text-align: right;
}
.personicon {
  margin-left: 0;
  width: 50px;
}
.timediv {
  width: 80px;
  vertical-align: middle;
  height: 24px;
  line-height: 24px;
}

li.booking {
  background-color: #121212;
  padding: 0;
  padding-left: 22px;
  padding-right: 22px;
}

.slider {
  -webkit-appearance: none;
  height: 8px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0);
  outline: none;
  opacity: 0.99;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 0;
  margin-left: -100%;
  padding: 0;
  width: 100%;
  display: inline;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Set a specific slider handle width */
  background: #286e8f; /* Green background */
  border: 2px solid #286e8f;
  cursor: pointer; /* Cursor on hover */
  height: 30px;
  border-radius: 20px;
  opacity: 0;
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  background: rgba(0, 255, 255, 1); /* Green background */
  cursor: pointer; /* Cursor on hover */
  height: 25px;
  border-radius: 50%;
  opacity: 1;
}
.slidecontainer {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0;
}
.sliderlabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  color: #121212;
  font-weight: bold;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: rgb(38 73 90);
  border-radius: 100%;
}
.sliderlabel.custom {
  background: #47aecc;
}
td.lock {
  width: 10px;
  align-content: center;
  padding-left: 10px;
  color: #286e8f;
}
td.lock.unlocked {
  width: 10px;
  align-content: center;
  padding-left: 10px;
  color: #47aecc;
}
.bookedpacing {
  background-color: #47aecc;
  position: absolute;
  left: 0;
  width: 50%;
  border-radius: 7px;
  height: 8px;
}
.unbookedpacing {
  background-color: #0090c1;
  position: absolute;
  left: 0;
  width: 50%;
  border-radius: 7px;
  height: 8px;
}
.additionalpacing {
  background-color: rgb(38 73 90);
  width: 100%;
  border-radius: 7px;
  height: 8px;
}

.bookingrow {
  width: 100%;
}
.icon {
  font-size: 1.5em !important;
  width: 160px;
  height: 60px;
  color: #286e8f;
  text-align: center;
  vertical-align: middle;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin: auto;
  z-index: 101;
}

.navbar table {
  width: 100%;
  background-color: #252525;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.navmiddle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.navmiddle button p {
  margin: 0;
}

table.bookingrow {
  border-bottom: 2px solid #2e2e2e;
  padding: 4px 8px;
}
li:last-of-type table {
  border-bottom: none;
}
.bookingrow button {
  height: 44px;
  width: 44px;
}
.bookingcovers {
  width: 90px;
}
.bookingleftbuttons {
  width: 1px;
  padding-right: 5px;
}
.bookingdetails {
  overflow: hidden;
  max-width: 0;
  padding-left: 15px;
}
.bookingrightbuttons {
  width: 1px;
  text-align: right;
}
.phonenumber {
  position: absolute;
  top: 10px;
  left: 60px;
  display: inline-block;
  font-size: 1.3em;
}
.emailbuttons .email {
  position: absolute;
  top: 12px;
  left: 40px;
  display: inline-block;
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
}
.phonenumber a,
.email a {
  color: #286e8f;
  text-decoration: none;
}
.horizontaltableheader {
  background-color: #252525;
  padding: 10px;
  text-align: center;
  position: relative;
}
.horizontaltableheader .buttons {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.horizontaltableheader .buttons button {
  margin-left: 10px;
  background: rgb(0 0 0 / 80%);
  border: 2px solid transparent;
  color: #286e8f;
  padding: 5px 5px 5px 20px;
  border-radius: 20px;
  font-weight: bold;
  position: relative;
  height: 29px;
  width: 65px;
  cursor: pointer;
}
.horizontaltableheader .buttons button.no {
  pointer-events: none;
  opacity: 0.4;
  color: #b5b5b5;
}
.horizontaltableheader .buttons button.yes {
  box-shadow: 0 0 4px rgb(0 0 0 / 50%);
}
.horizontaltableheader .buttons button.yes.selected {
  color: #070707;
  background: #47aecc;
  border-color: #252525;
}
.add-booking-holder-holder.walk-in .time .content p {
  margin: 0;
}
.add-booking-holder-holder.walk-in .time .content p span {
  font-weight: normal;
  font-size: 0.8em;
}
.horizontaltableheader .buttons button svg {
  height: 0.8em;
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
}
.horizontaltableheader .available {
  background: #121212;
  width: 30px;
  color: #b5b5b5;
  position: absolute;
  right: 10px;
  height: 30px;
  line-height: 30px;
  top: 6px;
  font-weight: bold;
  border-radius: 50%;
}

.horizontaltableheader .available.orange {
  color: orange;
}
.horizontaltableheader .available.red {
  color: red;
}

.fulltables {
  width: 100%;
  margin-top: 0px;
}
.fulltables tr,
.fulltables table,
.fulltables tbody,
.fulltables {
  display: block;
  width: 100%;
  overflow: auto;
}
td.horizontaltableheader {
  width: 100%;
  display: block;
  box-sizing: border-box;
}
.bookingrightbuttons button,
.bookingleftbuttons button,
.calendarbutton {
  position: relative;
  border-radius: 100px;
  border: none;
  background-color: #2c2c2c;
  color: #286e8f;
  text-indent: 0px;
  font-size: 15px;
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
  transition: width 0.3s;
  overflow: hidden;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bookingrightbuttons button {
  text-align: left;
}

.opentableview {
  height: calc(100% - 280px);
  overflow-x: hidden;
  overflow-y: auto;
}

.bookingnotes {
  margin: 0;
  font-size: 0.8em;
  margin-top: 3px;
  color: rgba(255, 255, 255, 0.38);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bookingname {
  margin: 0;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.87);
}
.alltableview {
  height: 100px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

.tablescontainer .tables {
  bottom: 0;
  background: #121212;
  position: absolute;
  left: 0;
  transition: bottom 0.5s;
  transition-delay: 0s;
  z-index: 10;
  overflow-y: hidden;
}
.opentableview .tables {
  bottom: -100px;
}
.status-button-container {
  display: flex;
  flex-direction: column;
}
.status-button-row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
}
.status-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-name {
  padding-left: 15px;
  text-transform: capitalize;
  color: #47aecc;
}
.status-button-row .seat-button {
  padding: 10px 20px;
  align-self: center;
}
.blackout {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 101;
}

.display-none {
  display: none;
}

.window-bg {
  background-color: rgb(17, 17, 17);
  display: flex;
  margin: auto 0;
  width: 90%;
  height: 80%;
  justify-content: center;
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
}
.status-selector {
  width: auto;
  height: auto;
  padding: 50px;
}
.status-button {
  padding: 0 !important;
  background-color: #2c2c2c;
  display: flex;
  float: left;
  border-radius: 200px;
  color: #47aecc;
  align-items: center;
  justify-content: center;
  margin: 5px;
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
  font-weight: bold;
  font-size: 0.8em;
  width: 36px;
  height: 36px;
  border: 2px solid #2c2c2c;
}
.table .status-button {
  margin: 5px 0 0 0;
  border: 0;
  height: 17px;
  padding: 1px 8px !important;
}
.orange-border {
  background-color: rgb(255 165 0 / 60%);
  color: black;
}
.red-border {
  background-color: rgb(255 0 0);
  color: black;
}
.status-button svg {
  margin: 0 auto;
  display: block;
}
.status-button.selected {
  border: 1px solid;
  width: 38px;
  height: 38px;
  color: #47aecc;
}
.tables {
  display: block;
  overflow-x: auto;
  width: inherit;
  left: inherit !important;
  position: fixed !important;
}
.tables tbody {
  display: block;
}

.table .red svg.MuiSvgIcon-root {
  font-size: 1em;
  position: absolute;
  margin-left: -3px;
  transform: rotate(10deg);
}

.table p {
  font-weight: bold;
}

p.red {
  color: red !important;
}
.tablebookings-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  align-self: flex-start;
  background-color: #121212;
}

.tablebookings-booking {
  display: flex;
  height: 50px;
  background-color: #252525;
  /* grid-gap: 10px; */
  padding: 5px;
  border-top: 2px solid rgb(18 18 18 / 50%);
}
.tablebookings-booking-row.selected {
  box-shadow: 0 0 0 4px #47aecc;
  z-index: 101;
}
.tablebookings-booking div {
  display: flex;
  align-items: center;
  padding: 10px;
}
.tablebookings-name {
  flex-grow: 1;
}

.expanded-booking-view {
  display: flex;
  padding: 10px;
  font-size: 14px;
  color: #286e8f;
}
.expanded-booking-view .details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  /* grid-gap: 10px; */
}
.expanded-booking-view .details > * {
  margin-bottom: 10px;
}
.expanded-booking-view .history-wrapper {
  display: flex;
  min-width: 140px;
  background-color: rgb(0 0 0 / 50%);
  padding: 10px;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
}
.history {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.history h3 {
  text-align: center;
  margin: 0 0 10px;
  color: rgba(255, 255, 255, 0.66);
}

.expanded-booking-view .tags {
  display: flex;
  flex-wrap: wrap;
}

.expanded-booking-view .detail,
.tags .detail {
  display: flex;
  margin-right: 10px;
  padding: 8px 14px 8px 8px !important;
  border-radius: 100px;
  align-self: flex-start;
  align-items: center;
  grid-gap: 10px;
  font-size: 16px;
}

.tags .tag {
  color: rgb(255 255 255 / 66%);
}

.tags .tag .circle {
  width: 17px;
  min-width: 17px;
  height: 17px;
  border-radius: 10px;
  display: block;
  padding: 0;
}
.columns {
  display: flex;
  flex-direction: row;
}

span.smart-turn {
  margin-left: 20px;
  color: #47aecc;
}
.column-label {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.66);
}
.column {
  margin-right: 20px;
}
span.label-text {
  margin-right: 20px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.66);
  text-align: center;
}
.history div {
  display: flex;
  align-items: center;
}
.tables-action-bar {
  position: fixed;
  width: calc(100% - 20px);
  background: #252525;
  height: 38px;
  margin-top: -38px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.tables-top-panel {
  display: flex;
  position: absolute;
  left: 40px;
  top: 40px;
  height: 200px;
  width: calc(100% - 80px);
  background-color: #121212;
  flex-direction: column;
}
span.panel-booking {
  display: inline-flex;
  width: 100%;
  height: 38px;
  align-items: center;
  justify-content: center;
  background: #252527;
}

.tables-top-panel .panel-buttons {
  display: flex;
  flex-grow: 1;
  /* grid-gap: 25px; */
  padding: 25px;
  padding-right: 0;
}

.panel-buttons > * {
  display: flex;
  flex-grow: 1;
  width: 100%;
  border: 2px solid #252525;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #286e8f;
  margin-right: 25px;
}

.panel-buttons > *.selected {
  color: #121212;
  background: #47aecc;
}
.panel-buttons .save {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  border: 0;
}

.save span.submit {
  position: unset;
  display: flex;
  justify-content: center;
  height: 30px;
}
.save span.table-list {
  color: rgba(255, 255, 255, 0.66);
}

.save > * {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel-buttons input {
  margin: 0;
  font-size: 1em;
}

.table-selected {
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border: 2px solid #47aecc;
  border-radius: 0 0 10px 0;
  display: flex;
  align-items: flex-end;
  color: #121212;
  justify-content: flex-end;
  justify-items: flex-end;
}

.table-selected.warning {
  border: 2px solid red;
}

.table-selected svg {
  margin-right: -2px;
  margin-bottom: -2px;
  background-color: #47aecc;
  border-radius: 10px 0 11px 0;
}
.table-selected.warning svg {
  background-color: red;
}
.turn-options {
  display: flex;
  /*grid-gap: 30px;*/
  margin: 8px;
  margin-right: 0;
}
.turn-options > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  width: 94px;
  height: 94px;
  padding: 0 !important;
  color: #286e8f;
  border: 3px solid transparent;
  background: rgba(0 0 0 / 30%);
  border-radius: 20px;
}
.turn-options .selected {
  border: 3px solid #47aecc;
  color: #47aecc;
}

.turn-options .string-time {
  font-weight: bold;
  font-size: 1.2em;
}
.turn-options > * {
  margin-right: 20px;
}

.expanded-booking-view .description {
  font-style: italic;
  border-radius: 20px;
  line-height: 20px;
  font-size: 16px;
  padding: 15px !important;
}
.window-bg.table-bookings {
  background-color: #2a2a2a;
  padding: 20px;
}
.bookingleftbuttons .selected {
  color: #47aecc;
  border: 2px solid #47aecc;
}
.tables-title {
  display: flex;
  padding: 21px;
  justify-content: center;
  background-color: #252525;
  font-weight: bold;
}
.tablebookings-container .no-bookings {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.tablebookings-table {
  border-radius: 100px;
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
  padding: 0 4px !important;
  color: #286e8f;
  height: 36px;
  min-width: 28px;
  font-size: 14px;
  justify-content: center;
  font-weight: bold;
  font-size: 15px;
  align-self: center;
  display: flex;
  border: 2px solid #2c2c2c;
  background: #2c2c2c;
  margin: 5px;
}
.tablebookings-table.manual {
  color: #47aecc;
  border: 2px solid;
}
.list-holder {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p.assigned {
  background: #47aecc;
  color: #121212;
  border-radius: 20px;
  padding: 1px 4px;
}

.bookingleftbuttons .status-button {
  height: 40px;
  width: 40px;
}
.plus-minus > * {
  background: red;
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
  background: rgba(0 0 0 / 30%);
  border-radius: 300px;
}

.plus-minus {
  background: transparent;
  /* grid-gap: 9px;*/
}
.plus-minus div:first-child {
  margin-bottom: 9px;
}
.add-booking-holder .wrapper .tags.selected {
  height: 146px;
  display: block;
  overflow: auto;
  padding: 10px;
}
.add-booking-holder .tags {
  max-width: calc(50% - 45px);
}
.add-booking-holder-holder {
  height: 210px;
  min-height: 210px;
  overflow: hidden;
  background: #252525;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  /* grid-gap: 40px; */
  z-index: 1;
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  transition: top 0.5s ease;
}
.add-booking-holder-holder.walk-in {
  z-index: 1001;
  height: 220px;
  min-height: 220px;
}

.add-booking-holder-holder.walk-in.hide {
  z-index: 10;
}
.add-booking-holder-holder.walk-in .time svg {
  height: 40px;
  width: 40px;
}
.add-booking-holder-holder.walk-in .time .content.option-selected {
  flex-direction: column;
}
.add-booking-holder-holder.walk-in .time .content {
  font-weight: bold;
}
.add-booking-holder-holder.hide {
  top: -158px;
}
.add-booking-holder {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-right: 0;
}
.walk-in .add-booking-holder {
  height: calc(100% - 40px);
}
.walk-in .time .content {
  font-size: 20px;
  width: 90%;
}
.add-booking-holder .wrapper > * {
  flex-grow: 1;
  width: 100%;
  border: 2px solid #286e8f;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* grid-gap: 10px; */
  background: #121212;
  margin-right: 20px;
}
.add-booking-holder .content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  flex-wrap: wrap;
  color: #286e8f;
  /* grid-gap: 10px; */
  /* grid-gap: 15px; */
  /* gap: 15px; */
}
.table-options .content > * {
  background: rgb(0 0 0 / 30%);
  border: 2px solid transparent;
  border-radius: 200px;
  flex-direction: column;
  height: 50px;
  width: 80%;
  display: flex;
  position: relative;
  font-size: 15px;
  flex: 1 1 33%;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.add-booking-holder h4 {
  margin: 0;
}
.add-booking-holder .next {
  border: 0;
  background: unset;
  /* grid-gap: 15px; */
  padding-bottom: 0;
}

.add-booking-holder .next > * {
  background: #121212;
  width: 100%;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 200px;
  color: #286e8f;
  margin: 10px 0;
}
.add-booking-holder svg {
  width: 50px;
  height: 50px;
}
.add-booking-holder .grey .content {
  color: rgb(255 255 255 / 50%);
}
.add-booking-holder .grey {
  border: 2px solid transparent;
  opacity: 0.5;
  color: rgb(255 255 255 / 50%);
  pointer-events: none;
}
.table-options .label {
  color: orange;
}
.table-options .highlight .label svg {
  color: green;
  height: 22px;
  width: 22px;
  margin-top: 2px;
}
.table-options .highlight {
  border: 2px solid green;
}
.add-booking-holder .covers p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.add-booking-holder .covers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table-options .quick .label {
  color: #286e8f;
}
.table-options .quick.highlight .label {
  color: green;
}
.spacer {
  height: 20px;
  transition: 0.5s ease;
}
.spacer.big {
  height: 240px;
}
.window-bg.time-changer {
  width: unset;
  height: unset;
}
.time-options-container {
  width: 300px;
  padding: 20px;
  background-color: #252525;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  font-weight: bold;
}
.add-booking-holder-holder .time-row {
  font-size: 0.8em;
  font-family: monospace;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background: #121212;
  border: 2px solid transparent;
}
.expanded-option .slider {
  margin-left: 0;
}
.expanded-option .time-row {
  font-size: 0.8em;
  font-family: monospace;
  display: flex;
  justify-content: center;
  align-items: center;
}
.time-options-container .time-row > * {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 16px;
}
.time-options-container .difference {
  width: 50px;
}
.time-options-container .time {
  width: 100px;
}
.time-options-container .result {
  flex: 1 1;
  color: orange;
}

.time-row.available {
  opacity: 1 !important;
  color: #286e8f;
}
.time-options-container .recommended .result {
  color: green;
}
.time-row.available.recommended {
  border: 2px solid green;
}
.add-booking-holder .covers.quick p {
  color: green;
}
.add-booking-holder .content.overwrite {
  color: green;
}
.table-options .selected {
  color: #47aecc;
  border-color: #47aecc;
}

.add-booking-holder .selected {
  border-color: #47aecc;
}
.add-booking-holder .selected {
  border-color: #47aecc;
}
.add-booking-holder .selected .content {
  color: #47aecc;
}
.add-booking-holder .wrapper {
  height: 170px;
  min-height: 170px;
  transition: 0.7s ease;
  display: flex;
  flex: 1 1;
  margin-bottom: 40px;
}
.add-booking-holder input {
  width: calc(100% - 20px);
  margin: 0;
  font-size: 16px;
}
.add-booking-holder textarea {
  height: 100%;
  width: calc(100% - 40px);
  background: #252525;
  color: #286e8f;
  padding: 10px;
  font-size: 16px;
  border: 0;
  margin: 10px;
}
.mandatory {
  color: orange;
}
.italic {
  font-style: italic;
}
.filled {
  color: #47aecc !important;
}
.add-booking-holder .tag {
  background: #252525;
  border: 2px solid #47aecc;
  color: #47aecc;
}
.add-booking-holder .tag.deselected {
  opacity: 0.6;
  border: 2px solid transparent;
  color: rgb(170 170 170);
}
.add-booking-holder .summary {
  color: #47aecc;
  border: 2px solid;
  border-radius: 0;
}

.add-booking-holder.part2 .wrapper:first-of-type {
  margin-top: -210px;
}
button.calendarbutton {
  color: #47aecc;
}
.add-booking-holder .table-options {
  padding-right: 0;
}
.add-booking-holder .basics > * {
  margin: 5px 10px;
}

.add-booking-holder .tags > * {
  margin: 5px;
}

.tables tr {
  -webkit-backface-visibility: hidden;
}
.tables tr > * {
  -webkit-transform: translate3d(0, 0, 0);
}
.bookingdetails .circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 10px;
  margin-left: 4px;
  opacity: 0.8;
}
.seat-button {
  background: #286e8f;
  color: #121212;
  padding: 0 5px 1px;
  border-radius: 100px;
  font-size: 15px;
  font-weight: bold;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seat-booking {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: #252525;
}
.seat-booking .header {
  margin: 20px;
  font-size: 24px;
}
.seat-booking .buttons {
  display: flex;
  width: 100%;
  justify-content: center;
}
.seat-booking .buttons > * {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121212;
  padding: 15px;
  border: 2px solid transparent;
  color: #286e8f;
  border-radius: 200px;
  font-weight: bold;
  box-shadow: 0 0 5px #121212;
}

.submit-new {
  background: #286e8f !important;
  color: #121212 !important;
  border: 2px solid #286e8f !important;
}
.bookings-main.minimize {
  display: none;
}

.bookings-wrapper {
  margin-top: 60px;
  padding-bottom: 90px;
}
.blob {
  background: rgb(71 174 204);
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  box-shadow: 0 0 0 0 rgb(71 174 204);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(71, 174, 204, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(71, 174, 204, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(71, 174, 204, 0);
  }
}

.loading .window {
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: center;
  color: rgb(71 174 204);
}

.loading .window .blob {
  margin-left: 30px;
}
input[type="range"] {
  pointer-events: none;
}
input[type="range"]::-webkit-slider-thumb {
  pointer-events: auto;
}
.table .status-button.near {
  color: orange;
  background-color: rgb(255 165 0 / 20%);
}
.table .status-button.over {
  color: red;
  background-color: rgb(255 0 0 / 30%);
}

.slider.lock {
  display: none !important;
}
.sliderlabel.lock {
  /* display: none; */
  color: #286e8f00;
  background: #47aecc !important;
  height: 20px;
  width: 4px;
  margin-left: -2px;
  border-radius: 0;
}
.fullWidth.bookingUL.hide {
  display: none;
}
.buttons-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
}
.buttons-wrapper button {
  border-radius: 100%;
  border: 0;
  width: 35px;
  height: 35px;
  margin: 10px 10px 0 0;
  display: flex;
  background: #2e2e2e;
  justify-content: center;
  align-items: center;
  color: #286e8f;
  border: 2px solid transparent;
}

.buttons-wrapper button.selected {
  color: #47aecc;
  background: #2e2e2e;
  border: 2px solid #47aecc;
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
}
.buttons-wrapper button svg {
  height: 70%;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #286e8f;
  border-radius: 5px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  overflow: overlay;
}
