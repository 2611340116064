@media screen and (max-width: 576px) {
  .add-booking-holder-holder {
    overflow-y: auto;
  }
  .add-booking-holder .wrapper > div {
    min-height: 180px;
    margin-bottom: 20px;
  }
  .add-booking-holder {
    height: 400px !important;
    margin-bottom: 110px !important;
  }
  .service-selector-wrapper.add-space {
    display: none;
  }
  .add-booking-holder .wrapper {
    margin-bottom: 0;
  }
  .login-wrapper,
  .login-form {
    max-width: 100% !important;
    width: 100% !important;
  }
  .login-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .login-form {
    display: block;
    width: 90%;
    margin-left: 5%;
  }
  .login-form img {
    margin-top: -130px;
    max-width: 90%;
  }
  input {
    width: 90%;
    margin: 0;
  }
  .login-form .error-message {
    box-shadow: 0 0 0 2px orange;
    padding: 10px 0;
    margin: 0;
    width: 90%;
  }
  .login-form button {
    margin-right: 10%;
    float: right;
  }
  .options-button-selected {
    max-width: 100%;
  }
  .options-button-selected {
    max-width: 100%;
    width: 100%;
    padding: 13px 0 !important;
  }
  .calendar-overlay td p {
    top: unset !important;
    bottom: 10px !important;
  }
  .window-bg {
    width: 100%;
    height: 90%;
  }
  li.booking {
    padding-left: 0;
    padding-right: 0;
  }
  .bookingcovers {
    width: 10px;
    text-align: center;
    padding-right: 10px;
  }
  .bookingdetails {
    padding-left: 0px;
  }
  .history-wrapper {
    display: none !important;
  }
  .window-bg.table-bookings {
    padding: 0 20px;
  }
  .tablebookings-container {
    margin-top: 20px;
  }
  .navbar-today {
    height: 43px;
    width: 43px;
    margin-left: 135px;
  }
  .add-booking-holder-holder {
    height: calc(100% - 70px);
  }
  .add-booking-holder {
    flex-direction: row;
    width: calc(200% - 40px);
    height: calc(100% - 140px);
  }
  .add-booking-holder .wrapper {
    flex-wrap: wrap;
    height: 100%;
  }
  .add-booking-holder .wrapper > div {
    width: calc(50% - 25px);
    height: calc(50% - 25px);
  }
  .keypad {
    margin-top: 80px;
  }
  .date-picker {
    width: 100%;
    margin-top: 80px;
    padding: 0;
  }
  .date-picker .options {
    flex-direction: column;
    margin-top: 20px;
  }
  .submit-time-picker {
    width: 50px;
    align-self: center;
    text-align: center;
    margin-top: 20px;
  }
  .service-selector-wrapper.add-space {
    left: -100px;
  }
  .add-booking-holder.part2 .wrapper:first-of-type {
    margin-top: 0;
  }
  .add-booking-holder.part2 {
    margin-left: calc(-100% + 18px);
  }
  .add-booking-holder-holder.hide {
    height: 210px;
  }
}
