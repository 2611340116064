.booking-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.booking-page-wrapper > * {
  height: 100%;
}
.booking-page-wrapper .header {
  display: flex;
}
.booking-page-wrapper .header > * {
  width: 100%;
}

.booking-page-wrapper .footer {
  display: none;
}
.booking-page-wrapper .back {
  color: #2f373c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
  width: 200px;
  cursor: pointer;
}
.booking-page-wrapper .back svg {
  font-size: 50px;
}

.booking-page-wrapper .widget-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.booking-page-wrapper .widget-holder {
  width: 400px;
  box-shadow: 0 0 5px rgb(0 0 0 / 60%);
  padding: 50px 20px 90px 20px;
  min-height: 70%;
  position: relative;
}
.booking-page-wrapper .widget-holder > * {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.booking-page-wrapper input {
  width: 200px;
  margin-bottom: 20px;
  height: 40px;
}

.booking-page-wrapper .blackout {
  background-color: rgba(0, 0, 0, 0.7);
}

.booking-page-wrapper .option-no {
  margin: 30px;
  font-size: 18px;
  font-style: italic;
  border-top: 2px solid #9e9e9e;
  padding-top: 20px;
  flex-direction: row;
}
.booking-page-wrapper .option-yes {
  margin-top: 30px;
  padding: 20px;
  border: 2px solid;
}
.booking-page-wrapper .option-no svg {
  margin-left: 10px;
}
.booking-page-wrapper .submit-bp {
  margin: 20px;
  border: 3px solid #9e9e9e;
  padding: 5px;
  width: 130px;
  background: #9e9e9e;
  color: black;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
}

.booking-page-wrapper .button-holder {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  flex-direction: row;
}
.booking-page-wrapper .widget-window-holder {
  width: 440px;
  overflow-x: hidden;
}

.booking-page-wrapper .widget-window {
  display: flex;
  margin-left: -440px;
  transition: margin 0.4s ease;
}
.booking-page-wrapper p {
  text-align: center;
}
.eui-time-picker {
  display: flex;
  flex-direction: row;
  width: 200px !important;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 20px !important;
  position: relative;
  z-index: 100;
}
.eui-time-picker p {
  padding: 10px 0;
  margin: 0;
}
.eui-time-picker .selector {
  width: 100%;
  overflow-y: scroll;
  background: #202224;
  z-index: 102;
  padding-bottom: 8px;
}

.eui-time-picker .selector div {
  padding: 6px 20px;
}
.eui-time-picker .min .selector {
  height: unset;
  overflow: auto;
  left: 0;
  right: unset;
}
.eui-time-picker .selector.min {
  overflow: auto;
}
.eui-time-picker .selector-wrapper {
  position: absolute;
  left: -2px;
  right: -2px;
  top: 100%;
  flex-direction: row;
  height: 150px;
  border: 2px solid white;
  border-top: 0;
  z-index: 102;
}
.eui-time-picker .selector::-webkit-scrollbar-thumb {
  background: rgb(73 73 73);
  border-radius: 10px;
  border: 4px solid #202224;
}
.eui-time-picker .selector::-webkit-scrollbar {
  background: rgb(32 34 36);
}
.eui-time-picker .done {
  position: absolute;
  top: 100%;
  left: -2px;
  right: -2px;
  background: #c2ad6e;
  color: #202224;
  padding: 8px;
  border: 2px solid white;
  border-top: 0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
.message svg.MuiSvgIcon-root,
.message i {
  color: orange;
}
.message svg.MuiSvgIcon-root,
.message i {
  color: orange;
}

.submit-bp.back {
  background: rgb(32, 34, 37) !important;
  color: rgb(194, 173, 110) !important;
}
.submit-bp.back {
  background: rgb(32, 34, 37) !important;
  color: rgb(194, 173, 110) !important;
}
.message a {
  color: #c2ad6e;
  font-size: 1.5em;
  margin-top: 50px;
}
.subtitle.message {
  margin-bottom: 0 !important;
}

.errorMessage {
  height: 50px;
  color: orange;
}
