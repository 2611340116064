.bookings-service-header {
  background-color: #252525;
  text-align: center;
  height: 30px;
  padding: 10px;
  line-height: 30px;
  font-size: 1.2em;
  font-weight: bold;
}

.bookings-main {
  position: relative;
  margin: 30px 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.service-selector-wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 99;
  top: 70px;
  left: 0;
  transition: all 0.5s ease;
}

.service-selector {
  padding: 6px 5px 5px 5px;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  background-color: #2c2c2c;
  transition: all 0.3s ease;
  margin-left: 0;
}

.service-selector-button {
  display: inline-block;
  padding: 10px;
  font-weight: bold;
  color: #286e8f;
  margin: 5px;
  border: 2px solid;
  background: #2c2c2c;
  box-shadow: 0 0 5px #121212;
  /* opacity: 0.8; */
}

.ssb-selected {
  color: #47aecc;
}
.expand-contract {
  background: #286e8f;
  margin-left: 10px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expand-contract svg {
  transform: rotate(180deg);
  transition: all 0.3s ease;
  transition-delay: 0.4s;
}
.expand-contract.hidden svg {
  transform: rotate(0deg);
}

.service-selector-wrapper.add-space {
  top: 290px;
}
.service-selector.hidden {
  margin-left: calc(-100% + 40px);
}
