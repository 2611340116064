.login-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 500px;
  position: relative;
}

.login-form img {
  max-width: 100%;
  opacity: 0.4;
  position: absolute;
  top: 0;
  margin-top: -170px;
}

.login-form input {
  font-size: 1.3em;
  margin-top: 20px;
  box-shadow: none;
}

.login-form button {
  background-color: #286e8f;
  border: none;
  padding: 10px 0;
  margin: 20px 10%;
  width: 100px;
  text-align: center;
  color: #252525;
  font-size: 1.3em;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  align-self: flex-end;
  border: 2px solid #286e8f;
}
.login-form .error-message {
  color: orange;
  border: 2px solid orange;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  background: rgba(255 165 0 / 0.1);
  margin: 10px 10%;
  height: calc(1em + 8px);
  transition: 0.5s ease;
  opacity: 0;
}
.login-form .error-message.visible {
  opacity: 0.6;
}
.login-form button.unclickable {
  background: #252525;
  color: #676767;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #286e8f;
}

.login-form button.clickable {
  cursor: pointer;
}
.login-form button.loading {
  border: 2px solid rgb(71 174 204);
  padding: 2px 0;
}

.login-form .changed {
  color: rgb(71 174 204);
}
