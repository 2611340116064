.options-button {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  display: inline-block;
  border-radius: 23px;
  padding: 5px;
  position: fixed;
  left: 20px;
  top: 8px;
  width: 36px;
  overflow: hidden;
  background-color: #2c2c2c;
  height: 36px;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.03, 0.99);
}

.options-button-selected {
  top: 0;
  left: 0;
  bottom: 0;
  width: 500px;
  z-index: 101;
  height: unset;
  border-radius: 0;
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.options-button svg {
  z-index: 1;
  position: relative;
  transition-duration: 1s;
  transition-property: transform;
}
.options-button-selected svg {
  transform: rotate(180deg);
  color: #47aecc;
}

.option {
  overflow: hidden;
  color: rgb(40 110 143);
  text-align: left;
  padding: 10px 20px;
  margin: 10px 0;
  font-size: 0.8em;
  background-color: #252525;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  height: 23px;
  white-space: nowrap;
  cursor: pointer;
}
.option.grey {
  pointer-events: none;
  color: grey;
  opacity: 0.7;
}

.sub-option {
  padding-left: 40px;
}

.expanded-option {
  position: absolute;
  width: 510px;
  overflow: scroll;
  top: 65px;
  bottom: 0;
  padding-bottom: 10px;
  background-color: #2c2c2c;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.03, 0.99);
  left: -510px;
}

.option-full {
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.03, 0.99);
  left: 0;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.schedule-container {
  position: relative;
  margin-top: 20px;
  width: 94%;
}

.schedule-week {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-size: 0.7em;
  top: 40px;
  width: 90%;
  margin-left: 5%;
  max-height: 0px;
  transition-timing-function: ease;
  transition-duration: 0.5s;
  overflow: hidden;
}
.schedule-week.selected {
  max-height: 480px;
}
.schedule-overview svg {
  transition-duration: 0.5s;
  transition-property: transform;
}
.schedule-overview.selected svg {
  transform: rotate(-180deg);
}
.schedule-week table td,
.schedule-week table th {
  padding: 5px;
  text-align: left;
  padding-left: 20px;
  font-size: 0.8em;
}
.schedule-overview {
  position: relative;
  width: 94%;
  margin-left: 3%;
  font-size: 0.8em;
}
.schedule-overview td {
  width: 45%;
  font-size: 0.8em;
}
.schedule-overview td.small {
  width: 10%;
}
.schedule-label {
  color: rgba(255, 255, 255, 0.66);
  font-weight: normal;
}
.schedule-overview table {
  padding: 5px;
}
.options-title {
  padding: 0 0 10px 0;
  color: rgba(255, 255, 255, 0.6);
}
.schedule-new-button {
  font-size: 0.7em;
  background-color: #252525;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 5px;
  margin-top: 15px;
  padding: 5px 15px 5px 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  display: inline-block;
  transition-duration: 0.5s;
}
.schedule-new-button svg {
  font-size: 1.2em;
  margin-right: 5px;
  vertical-align: bottom;
  z-index: unset;
}
.double-row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.double-row.labeled p {
  display: inline-block;
  width: 20%;
  color: rgba(255, 255, 255, 0.66);
  font-size: 0.7em;
  margin: 0;
}
.expand-arrow svg {
  z-index: unset;
}
.schedule-modify-button {
  float: right;
  padding: 5px;
  background-color: #2c2c2c;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  margin: 5px;
  border-radius: 5px;
}
.schedule-modify-button svg {
  font-size: 1em;
  margin-right: 5px;
  z-index: unset;
}
.double-row.labeled input {
  margin: 0 !important;
  margin-top: 5px !important;
  width: 40%;
}
.select-dropdown {
  z-index: 102;
  position: absolute;

  left: 20%;
  top: 0;
  background-color: #252525;
  width: 40%;
  color: #286e8f;
  font-size: 0.8em;
  opacity: 0;
  transition: 0.5s;
  border-radius: 20px;
}
.select-dropdown {
  opacity: 1;
}
.select-dropdown h3 {
  color: inherit;
  font-size: 1em;
  font-weight: normal;
}
.select-dropdown.right {
  left: unset;
  right: 0;
}
.options-menu {
  padding-top: 10px;
  width: 93%;
  position: relative;
}
.options-button-selected div svg {
  transform: rotate(0);
}
.edit-schedule-pt {
  margin: 20px 0 0;
  background: #252525;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.edit-schedule {
  width: 88%;
}
.edit-schedule input {
  width: 100%;
  margin: 20px 0 0;
}

.edit-schedule-pt input {
  margin: 0;
  box-shadow: none;
}

.edit-schedule-pt .double-row {
  width: 100% !important;
  margin: 0;
}
.table-plan-container {
  width: 80%;
  margin-left: 10%;
  position: relative;
  padding-top: 10px;
}
.table-plan {
  font-size: 1.2em;
}

.table-plan .schedule-modify-button {
  font-size: 0.8em;
}
.expanded-option textarea {
  background-color: #2c2c2c;
  border: 0;
  color: #286e8f;
  height: 100%;
  display: inline-block;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  width: 45%;
  font-size: 0.7em;
  margin: 10px 10px 0 0;
  padding: 5px;
}
form.edit-table-plans input {
  width: 94%;
  margin-left: calc(3% - 15px);
  margin: 20px 0 0;
}
.edit-table-plans .edit-schedule-pt {
  width: 94%;
  margin-left: 3%;
  padding-bottom: 10px;
  overflow: hidden;
}
.edit-table-plans p {
  width: 10% !important;
}
.edit-table-plans .schedule-modify-button {
  float: right;
  margin: 10px 10px 0;
  font-size: 0.7em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-table-size .other input {
  background-color: #2c2c2c;
  width: 60px;
  font-size: 1.1em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  font-family: monospace;
  padding: 6px;
  border: 2px solid #2c2c2c;
  margin-right: 8px;
  margin-top: 0;
}
.new-table-size .other {
  font-size: 0.8em;
}
.new-table-size .button {
  background-color: #2c2c2c;
  /* display: inline-block; */
  padding: 6px;
  font-family: monospace;
  margin-right: 8px;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

  border: 2px solid #2c2c2c;
}
.new-table-size {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 10px 10px 0;
}
.new-table-size .tick {
  color: #2c2c2c;
  background-color: #252525;
  font-weight: bold;
  margin-right: 0;
}
.new-table-size .active {
  background: #2c2c2c;
  color: #286e8f;
}
.new-table-size .selected.button,
.new-table-size .selected input {
  border: 2px solid #286e8f;
}
.edit-table-plans {
  padding-bottom: 110px;
  width: 100%;
}
.time-row-container {
  background: #252525;
  width: 94%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  clear: both;
  margin-top: 20px;
  padding: 10px 0;
}
.time-options-container .time-row {
  font-size: 0.8em;
  font-family: monospace;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  background: #121212;
  border: 2px solid transparent;
}

.time-row div {
  display: inline-block;
  align-self: stretch;
}
.time-row .time {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-name input {
  height: 1em;
  padding: 0.5em;
  font-size: 0.8em;
  background-color: #2c2c2c;
  margin: 0;
}

.time-row .service-name {
  position: relative;
  width: 120px;
  padding: 5px;
  align-self: stretch;
  display: flex;
  justify-content: center;
}
.checkbox svg {
  font-size: 1em;
}
.checkbox {
  background: #2c2c2c;
  padding: 0.5em 0.3em;
  height: 1em;
  width: 1.3em;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.time-row-slider {
  position: relative;
  flex-grow: 1;
  padding-right: 5px;
  display: flex !important;
  align-items: center;
}
.time-row-slider .additionalpacing {
  margin-top: 0;
  left: 0;
  width: calc(100% - 10px);
  height: 6px;
}
.time-row-slider .slider {
  width: calc(100% - 10px);
  left: 0;
  position: absolute;
  height: 6px;
}
.time-row-slider .sliderlabel {
  top: unset;
  width: 30px;
  text-align: center;
  pointer-events: none;
  color: #121212;
  margin: 0;
  font-weight: bold;
  line-height: 30px;
  display: flex;
  align-self: center;
  justify-content: center;
}
button.logout {
  background: #286e8f;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #252525;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  box-shadow: 0 0 5px rgb(0 0 0 / 50%);
  margin-top: 30px;
  font-weight: bold;
  border-radius: 5px;
  align-self: flex-end;
  margin-right: 3.5%;
}
.locked {
  position: absolute;
  background: rgb(0 0 0 / 40%);
  height: 87.5%;
  width: 106%;
  top: 15%;
  left: -3%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.locked svg {
  width: 50%;
  height: 50%;
  color: grey;
}
.options-menu .title {
  color: #47aecc;
  margin: 10px 0 30px 0;
  text-align: center;
}
hr.continue-service {
  position: absolute;
  width: 2px;
  height: calc(100% + 10px);
  padding: 0px;
  border: 0px;
  background-color: rgb(40 110 143);
  margin-top: -8px;
  margin-bottom: -8px;
}
.end-service {
  background: #286e8f;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  align-self: center !important;
  justify-self: center;
  margin-top: -13px;
}

hr.last-service {
  height: 50%;
}
.open {
  padding: 5px;
}
.time-row .settings-row {
  width: 225px;
  height: 30px;
  line-height: 30px;
  text-align: right;
  padding-right: 20px;
}
.time-row-slider input {
  background-color: #286e8f;
}

.settings-field {
  flex-grow: 1;
  display: flex !important;
}

.settings-field input {
  margin: 0;
  width: calc(100% - 10px);
  font-size: 0.9em;
  padding: 5px 0;
  background-color: #2c2c2c;
}
.settings-field.double input {
  width: calc(30% - 10px);
}

.time-row .hours-settings {
  width: 100%;
  padding: 10px 0;
}
.time-row .hours-settings input {
  width: 50px;
  margin: 0 10px;
}
.add-service {
  height: 4px;
  width: 4px;
  box-shadow: 0 0 0 2px #286e8f;
  background: #2c2c2c;
  border-radius: 100%;
  border: 6px solid #2c2c2c;
  transition-duration: 0s;
  opacity: 1;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  overflow: hidden;
  justify-self: center;
  align-self: center !important;
  z-index: 1;
  transition-duration: 0.2s;
}
.grey-out {
  color: rgba(255, 255, 255, 0.33);
  opacity: 0.5;
}
