.label-bookable {
  width: calc(45% - 10px);
  padding-left: 10%;
  display: inline-block;
  color: rgba(255, 255, 255, 0.66);
  font-size: 0.7em;
}
.label-offline {
  width: calc(45% + 10px);
  display: inline-block;
  color: rgba(255, 255, 255, 0.66);
  font-size: 0.7em;
}
.expanded-option textarea {
  font-family: sans-serif;
  font-size: 0.6em !important;
}
